<template>
  <div v-if="show" class="fixed z-60 inset-0 overflow-y-auto">
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        :class="
          `inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:align-middle pt-3 ${maxWidth} sm:w-3/5 md:w-2/3`
        "
      >
        <div class="absolute top-0 right-0 pt-4 pr-4">
          <button
            @click.prevent="show = false"
            type="button"
            class="bg-white rounded text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">Close</span>
            <svg
              class="h-4 w-4 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-base leading-6 font-medium text-gray-900 px-4">
              {{ title }}
            </h3>
            <div class="m-4">
              <component
                :is="component"
                @close-modal="closeAndEmit"
                :message="message"
                :title="title"
                :resource="resource"
                :redirect="redirect"
                :payload="data"
                :parent_id="parent_id"
                ref="form"
              />
              <template v-if="dialogType !== 'wizard'">
                <div
                  v-if="type === 'delete'"
                  class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                >
                  <button
                    @click.prevent="deleteAndClose"
                    type="button"
                    class="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-2 py-1 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Löschen
                  </button>
                  <button
                    type="button"
                    @click.prevent="show = false"
                    class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>

                <div
                  v-else-if="type === 'confirm'"
                  class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                >
                  <button
                    @click.prevent="closeAndEmit"
                    type="button"
                    class="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-2 py-1 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Bestätigen
                  </button>
                  <button
                    type="button"
                    @click.prevent="show = false"
                    class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>

                <div v-else class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    @click.prevent="$refs.form.save"
                    type="button"
                    class="w-full inline-flex justify-center rounded border border-transparent shadow-sm px-2 py-1 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Speichern
                  </button>
                  <button
                    type="button"
                    @click.prevent="show = false"
                    class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    Abbrechen
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios'

export default {
  name: 'modal',
  data() {
    return {
      show: false,
      title: null,
      resource: null,
      redirect: null,
      component: null,
      payload: null,
      maxWidth: 'w-4xl',
      showButtons: false,
      parent_id: null,
      event: null,
      data: null,
      callback: null,
      dialogType: null,
      type: null,
    }
  },
  created() {
    this.$bus.$on('show-modal', payload => {
      this.show = payload.show
      this.title = payload.title
      this.resource = payload.resource
      this.redirect = payload.redirect
      this.component = payload.component
      this.data = payload.data
      this.message = payload.message
      this.maxWidth = payload.maxWidth
      this.parent_id = payload.parent_id
      this.event = payload.event
      this.callback = payload.callback
      this.dialogType = payload.dialogType
      this.type = payload.type
    })
  },
  methods: {
    closeAndEmit(payload) {
      this.show = false
      payload['noRedirect'] = payload.noRedirect || false

      if (this.event) this.$bus.$emit(this.event, payload)

      if (!payload.noRedirect) {
        this.redirect && this.$router.push(this.redirect)
      }

      this.callback && this.callback()
    },
    deleteAndClose() {
      axios
        .delete(this.resource)
        .then(() => {
          this.closeAndEmit({ show: false })

          // this.$toast.success(response.data.message)
        })
        .catch(
          err =>
            err.data && err.data.message && this.$toast.error(err.data.message)
        )
    },
  },
}
</script>

<style>
.z-60 {
  z-index: 60;
}
</style>
